.SonicModal_dialog {
  background-color: rgba(74, 74, 74, 0.3);
}

.SonicModal_close_icon {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 14px;
  color: var(--color-white);
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}

.SonicModal_close_icon svg {
  stroke: var(--color-white);
}

.SonicModal_dialog .MuiPaper-root.MuiDialog-paper {
  background-color: var(--color-secondary) !important;
  max-width: max-content;
  border-radius: 10px;
  padding: 25px 50px;
  width: fit-content;
  max-width: 80vw;
}

.SonicModal_dialog .SonicModal_title {
  font-size: 22px !important;
  color: var(--color-white);
  width: 100%;
  text-align: center;
  margin: 15px 0px 20px 0px;
}
