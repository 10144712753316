html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1rem = 10px */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-primary);
  color: var(--color-white);
}

@media only screen and (max-width: 1000px) {
  html {
    font-size: 50%;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  -webkit-text-size-adjust: 100%; /* will ensure that the text doesn't get size adjusted automatically by the browser. */
  scrollbar-width: thin;
  background: var(--color-bg);
}

wave {
  cursor: pointer !important;
}

.custom_modal_container .MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.MuiMenu-paper .MuiMenuItem-root {
  font-family: var(--font-primary) !important;
  font-size: 16px !important;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  background-color: var(--color-bg);
  border: 1px solid var(--color-white) !important;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 18px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 18px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary);
}

textarea,
input {
  caret-color: var(--color-white);
}

.highlight_text {
  color: var(--color-primary);
}

.cs_card_container {
  color: var(--color-primary);
  background-color: var(--color-secondary);
  border-radius: 25px;
}
